export default [
  {
    path: '/cms/dqplatform/userinfoexamine',
    name: 'userinfoexamine',
    component: resolve => require(['@/views/cmsPage/dqPlatform/userinfoexamine/index.vue'], resolve)
  },
  {
    path: '/cms/dqplatform/logisticsmonitor',
    name: 'logisticsmonitor',
    component: resolve => require(['@/views/cmsPage/dqPlatform/LogisticsMonitoring/index.vue'], resolve)
  },
  {
    path: '/cms/dqplatform/taskSwitch',
    name: 'taskSwitch',
    component: resolve => require(['@/views/cmsPage/dqPlatform/taskSwitchManage/index.vue'], resolve)
  },
  {
    path: '/cms/dqplatform/userblackrellist',
    name: 'userblackrellist',
    component: resolve => require(['@/views/cmsPage/dqPlatform/userBlackLinkManage/index.vue'], resolve)
  },
  {
    path: '/cms/dqplatform/userlogisticsmannage',
    name: 'userlogisticsmannage',
    component: resolve => require(['@/views/cmsPage/dqPlatform/userLogisticsPriceManage/index.vue'], resolve)
  },
  {
    path: '/cms/dqplatform/newordermannage', // 订单管理---新
    name: 'newordermannage',
    component: resolve => require(['@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/index.vue'], resolve)
  },
  {
    path: '/cms/dqplatform/neworderreturnmannage', // 退款订单管理---新
    name: 'neworderreturnmannage',
    component: resolve => require(['@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/index.vue'], resolve)
  },
]
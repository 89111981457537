// 2、防抖功能函数，接受传参
import untils from "@/untils/index"

export function debounce(fn, wait = 1000) {
    // 4、创建一个标记用来存放定时器的返回值
    let timeout = null;
    return function() {
        // 5、每次当用户点击/输入的时候，把前一个定时器清除
        clearTimeout(timeout);
        // 6、然后创建一个新的 setTimeout，
        // 这样就能保证点击按钮后的 interval 间隔内
        // 如果用户还点击了的话，就不会执行 fn 函数
        timeout = setTimeout(() => {
            fn.call(this, arguments);
        }, wait);
    };
}

/** 节流 */
export function throttle(fn, wait = 1000) {
    // 4、通过闭包保存一个标记
    let canRun = true;
    return function() {
        // 5、在函数开头判断标志是否为 true，不为 true 则中断函数
        if(!canRun) {
            return;
        }
        // 6、将 canRun 设置为 false，防止执行之前再被执行
        canRun = false;
        // 7、定时器
        setTimeout( () => {
            fn.call(this, arguments);
            // 8、执行完事件（比如调用完接口）之后，重新将这个标志设置为 true
            canRun = true;
        }, wait);
    };
}


/**
 * @param objAttr 需要转化成的对象的对应数组中的属性值
 * @param objValue 需要转化成对象的值所对应数组中的值
 * @param objArr  需要转化的对象数组
 * */
export const objArrMapObj = (objAttr, objValue, objArr) => {
    const obj = {}
    objArr.forEach(el => {
        obj[el[objAttr]] = el[objValue]
    })
    return obj
}

/** 数据 */
export const uniqueArray = (array, valueAttr) => {
    console.log(array, 'sl')
    return array.filter((item, index) => {
        return array.findIndex(obj => obj[valueAttr] === item[valueAttr]) === index;
    });
}

/** 计算像素 */
export const getDPI = () => {
    let arrDPI = new Array;
    if (window.screen.deviceXDPI) {
        arrDPI[0] = window.screen.deviceXDPI;
        arrDPI[1] = window.screen.deviceYDPI;
    } else {
        let tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = parseInt(tmpNode.offsetWidth);
        arrDPI[1] = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
    }
    return arrDPI;
}

//px转毫米

export const pxToMm = (value) => {
    let inch = value / getDPI()[0];
    let c_value = inch * 25.4;
    return c_value;
}

//毫米转px
export const mmToPx = (value) => {
    let inch = value / 25.4;
    let c_value = inch * getDPI()[0];
    return c_value
}

/** 鉴定扫码枪输入事件 */
export const scanCodeWatch = (customCallback) => {
    // 监听按键
    let code = ''
    let lastTime, nextTime // 上次时间、最新时间
    let lastCode, nextCode // 上次按键、最新按键
    document.onkeypress = (e) => {
        // 获取按键
        if (window.event) { // IE
            nextCode = e.keyCode
        } else if (e.which) { // Netscape/Firefox/Opera
            nextCode = e.which
        }
        // 如果触发了回车事件(扫码结束时间)
        if (nextCode === 13) {
            if (code.length < 3) return // 手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有
            customCallback(code) // 获取到扫码枪输入的内容，做别的操作
            code = ''
            lastCode = ''
            lastTime = ''
            return
        }
        nextTime = new Date().getTime() // 记录最新时间
        if (!lastTime && !lastCode) { // 如果上次时间和上次按键为空
            code += e.key // 执行叠加操作
        }
        // 如果有上次时间及上次按键
        if (lastCode && lastTime && nextTime - lastTime > 30) { // 当扫码前有keypress事件时,防止首字缺失
            code = e.key
        } else if (lastCode && lastTime) {
            code += e.key
        }
        lastCode = nextCode
        lastTime = nextTime
    }
}

/** 截取字符串斜杠后的数字 */
export const matchGongStr = (str) => {
    if (str.includes('/')) {
        const regex = /\/(\d+)$/;  // 匹配斜杠后的数字
        const match = str.match(regex);
        return match ? match[1] : '';
    } else {
        return str
    }
}

/** 销毁扫码枪时间 */
export const destroyKeyPress = () => {
    document.onkeydown = ''
}

/** 查询字符串是否有heic */
export const addStringIfContains = (str, substring, addStr) => {
    if (!str.includes(substring)) {
        str += addStr;
    }
    return str;
}

/** 过滤包含key的项 */
export const findItemsByMapId = (arr, mapId) => {
    return arr.filter(item => item.mapId.includes(mapId));
}

/** 获取本地环境 local dev production*/
export const getLocalEnv = () => {
    return process.env.NODE_ENV
}

/** 判断对象中的值是否都是undefund，或 都不是undefund */
export const checkObjIsAllEmptyOrNotAllEmptyStatus = (tempObj) => {
    // 获取对象中所有值的数组
    const values = Object.values(tempObj);
    // 使用Array.prototype.every()检查是否所有值要么都是undefined，要么都不是undefined
    return values.every(
        (value, index, array) => (value === undefined || value == null || value === '') || array.every(val => (val !== undefined || val != null || val !== ''))
    );
}

/** 给一个对象，返回值不是空的对象 */
export const filterEmptyProperties = (obj) => {
    // 创建一个新对象来存储非空属性
    const result = {};

    // 遍历原始对象的所有属性
    for (const key in obj) {
        // 检查属性值是否不是undefined且不是空字符串
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== "") {
            // 如果不是，则将其添加到结果对象中
            result[key] = obj[key];
        }
    }

    // 返回结果对象
    return result;
}

/** 过滤对象中值为0的数据 */
export const filteredParams = (params) => {
    return Object.keys(params).reduce((acc, key) => {
        if (params[key] !== 0) {
            acc[key] = params[key];
        }
        return acc;
    }, {});
}

/** 转成Base64 */
export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

/** 判断截取数据---返回截取之后的数据（非截取） */
export const getUrlBeforeQuestionMark = (str, splitStr) => {
    // 找到"?"在字符串中的位置
    const index = str.indexOf(splitStr);
    // 如果找到了"?"，则返回从字符串开始到"?"之前的子字符串
    // 如果没有找到，返回整个字符串（因为整个URL都不包含"?"）
    if (index !== -1) {
        return str.substring(0, index);
    }
    return str;
}

/** 去往其他页面-打开新的窗口 */
export const goToOtherPage = (url) => {
    const origin = location.origin + '/#'
    window.open(origin + url);
}


/** 图片上传回显返回回显结构---ant
 * @param imgList {Array} 图片的数组
 * */
export const returnAntUploadImgObj = (imgList = []) => {
    if (!imgList.length) return []
    return imgList.map((item) => {
        return {
            uid: untils.randomString(5),
            name: "image",
            status: "done",
            url: item,
        };
    });
}

/** 对象赋值，用于编辑回显从列表中靠背数据到编辑接口所需的数据
 * @param baseObj {Object}
 * @param complexObj {Object}
 * */
export const updateBaseObjectWithComplex = (baseObj, complexObj) => {
    // 遍历复杂对象的所有属性
    for (let key in complexObj) {
        // 检查该属性是否存在于基础对象中
        // eslint-disable-next-line no-prototype-builtins
        if (baseObj.hasOwnProperty(key)) {
            // 如果存在，则将复杂对象中的值赋给基础对象
            baseObj[key] = complexObj[key];
        }
        // 注意：这里没有处理复杂对象的嵌套对象或数组，只处理了一级属性
    }
    // 返回更新后的基础对象（虽然通常我们是在原地修改baseObj，所以这一步是可选的）
    return baseObj;
}

/** 判断是输入的内容是否是符合正确的日期格式：yyyy-MM-dd */
export const isValidDate = (dateString) => {
    // 定义正则表达式来匹配 yyyy-MM-dd 格式
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

    // 测试输入字符串是否匹配正则表达式
    if (!regex.test(dateString)) {
        return false;
    }

    // 解析日期字符串
    const date = new Date(dateString);

    // 检查解析后的日期是否有效（例如，不是 Invalid Date）
    // 并且确保日期字符串解析后的年份、月份和日期与输入匹配
    const [year, month, day] = dateString.split('-').map(Number);
    return date instanceof Date &&
        !isNaN(date) &&
        date.getFullYear() === year &&
        (date.getMonth() + 1) === month && // 月份从0开始，所以要加1
        date.getDate() === day;
}

/** 判断日期是否符合yyyy-MM-dd，如果是yyyy-M-d格式返回：yyyy-MM-dd，如果不是日期则返回错误 */
export const isValidAndFormatDate = (input) => {
    // 正则表达式用于匹配 yyyy-MM-dd 或 yyyy-M-d 格式
    const dateRegex = /^(\d{4})-([01]?\d)-([0123]?\d)$/;
    const match = input.match(dateRegex);

    if (match) {
        const [_, year, month, day] = match;
        const formattedMonth = month.length === 1 ? '0' + month : month;
        const formattedDay = day.length === 1 ? '0' + day : day;
        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

        // 使用 Date 对象检查日期的有效性
        const date = new Date(formattedDate);
        // 检查年份、月份和日期是否与输入的格式化日期匹配
        if (
            date.getFullYear() === parseInt(year, 10) &&
            (date.getMonth() + 1).toString().padStart(2, '0') === formattedMonth && // 月份从0开始，需要+1
            date.getDate().toString().padStart(2, '0') === formattedDay
        ) {
            return formattedDate;
        } else {
            return null; // 日期无效
        }
    } else {
        // 输入不符合日期格式
        return null;
    }
}


export default [
    {
        path:'/cms/sellerguide/questionmanage',
        name:'questionmanage',
        component: resolve => require(['@/views/cmsPage/contentDataMange/openShopStudyManage/index'], resolve)
    },
    {
        path: '/cms/dqmanage/faq', // faq内容列表管理
        name: 'faqManageFaq',
        component: resolve => require(['@/views/cmsPage/contentDataMange/faqAboutManage/faqListManage/index.vue'], resolve)
    },
    {
        path: '/cms/dqmanage/faqcategory', // faq分类管理
        name: 'faqManage',
        component: resolve => require(['@/views/cmsPage/contentDataMange/faqAboutManage/faqCategoryManage/index.vue'], resolve)
    },
]
<template>
  <div :class="['preview-content',!out ? 'prevent-out' : '']">
    <div class="header">
      <span>{{list[currentIndex][baseTitleField]||''}}</span>
      <div style="display:flex;flex-direction:flex-end;align-items:center">
        <a-button v-if="showMute" @click="openDetail(baseUrl+list[currentIndex][baseImgField])" type='primary'>扫描图片</a-button>
        <!-- <span @click="close()" class="close">退出</span> -->
        <a-icon @click="close()" class="close"  type="close-circle" />

      </div>

    </div>
<!--  frontOrBack: "正面" ,percent: "0.999965"  -->
    <div class="content"  @mouseover="setShow" @mouseleave="setHiden">
      <div
          class="next-class coin-box left-10 top-20"
          v-if="showBigCoinName && showBigCoinStatus"
      >{{ showBigCoinName }}</div>
      <a-icon v-if='isMore' class="next-class" @click="previous" style="font-size:50px;color:#fff" type="left-circle" />
      <div class="show-img" ref='imgel'>
        <img
            v-if="maxWidth&&maxHeight"
            :style='"max-width:" + 400 + "px;"+"max-height:" + 600 + "px;" + bigImgStyle'
            :src="baseUrl+list[currentIndex][baseImgField]"
            alt=""
        />
        <img
            class="match-img"
            v-if="isFiringCheckMatch"
            :src="icons.matchIcon"
            :style='bigMatchImgStyle'
            alt=""
        >
      </div>
      <a-icon v-if="isMore"  class="right-class" @click="next" style="font-size:50px;color:#fff;" type="right-circle" />
      <a-button
          v-if="showBigCoinStatus"
          type="danger"
          class="right-class coin-box-button right-10"
          @click="handlePushBigCoinBadCase(currentIndex)"
      >币种检测收集</a-button>
      <a-button
          v-if="showShellStatus"
          type="danger"
          class="right-class coin-box-button right-0"
          @click="handlePushShellBadCase(currentIndex)"
      >盒子币检测错误收集</a-button>
    </div>
    <div class="footer">
      <div class="preview" v-for="(item,index) of list" :key='index'>
        <img @click="current(index)" :class="currentIndex == index ? 'in-footer' : 'out-footer'" style="max-width:70px;max-height:70px;" :src="baseUrl+item[baseImgField]||item" alt="">
      </div>
    </div>
    <div class="swiper-switch-box-box" v-if="isFiringCheckMatch">
      <div class="jin-box" v-if="true">
        <a-button size="small" type="primary" @click="handleSaveInputValue">保存</a-button>
        <a-button class="ml-20" size="small" type="danger" @click="handleResetInputValue">重置</a-button>
      </div>
      <div class="swiper-switch-box">
        <div class="swiper-box-cell">
          <div>上下位移：</div>
          <div style="width: 50%"><a-slider v-model="inputValueY" :min="-100" :max="100" /></div>
          <div><a-input-number v-model="inputValueY" size="small" :min="-100" :max="100" style="marginLeft: 16px" /></div>
        </div>
        <div class="swiper-box-cell">
          <div>左右位移：</div>
          <div style="width: 50%"><a-slider v-model="inputValueX" :min="-100" :max="100" /></div>
          <div><a-input-number v-model="inputValueX" size="small" :min="-100" :max="100" style="marginLeft: 16px" /></div>
        </div>
        <div class="swiper-box-cell">
          <div>旋转：</div>
          <div style="width: 50%"><a-slider v-model="inputValueR" :min="1" :max="360" /></div>
          <div><a-input-number v-model="inputValueR" size="small" :min="1" :max="360" style="marginLeft: 16px" /></div>
        </div>
        <div class="swiper-box-cell">
          <div>缩放：</div>
          <div style="width: 50%"><a-slider v-model="inputValueS" :min="0.1" :max="3.1" :step="0.01"/></div>
          <div><a-input-number v-model="inputValueS" size="small" :min="0.1" :max="3.1" :step="0.01" style="marginLeft: 16px" /></div>
        </div>
        <div class="swiper-box-cell">
          <div>倾斜X轴：</div>
          <div style="width: 50%"><a-slider v-model="inputValue3DX" :min="-90" :max="90" /></div>
          <div><a-input-number v-model="inputValue3DX" size="small" :min="-90" :max="90" style="marginLeft: 16px" /></div>
        </div>
        <div class="swiper-box-cell">
          <div>倾斜Y轴：</div>
          <div style="width: 50%"><a-slider v-model="inputValue3DY" :min="-90" :max="90" /></div>
          <div><a-input-number v-model="inputValue3DY" size="small" :min="-90" :max="90" style="marginLeft: 16px" /></div>
        </div>
        <div class="swiper-box-cell">
          <div>倾斜Z轴：</div>
          <div style="width: 50%"><a-slider v-model="inputValue3DZ" :min="-90" :max="90" /></div>
          <div><a-input-number v-model="inputValue3DZ" size="small" :min="-90" :max="90" style="marginLeft: 16px" /></div>
        </div>
      </div>
    </div>
    <div class="check-box-match">
      <a-checkbox :checked='isFiringCheckMatch' @change="handleChangeFiringCheckMatch">是否启用钱币校准</a-checkbox>
    </div>
  </div>
</template>

<script>
import {throttle} from '@/untils/otherEvent.js'
export default {
    data(){
        return {
          inputValueS: 1.00,
          inputValueR: 0,
          inputValueX: 0,
          inputValueY: 0,
          inputValue3DZ: 0,
          inputValue3DY: 0,
          inputValue3DX: 0,
          icons: {
            matchIcon: require('./_static/match_img.png')
          },
          isFiringCheckMatch: false,
          bigImgStyle: '',
          paramsData: {
            zoomVal: 1,
            left: 0,
            top: 0,
            currentX: 0,
            currentY: 0,
            flag: false,
          },
          maxWidth:null,
          maxHeight:null,
          out:true,
          isFor:false,
          isMore:false,
          change3DAttr: '',
          change3DTypeVale: 0
        }
    },
  watch: {
    inputValue3DZ(newVale) {
      this.change3DTypeVale = newVale
      this.change3DAttr = 'z'
    },
    inputValue3DX(newVale) {
      this.change3DTypeVale = newVale
      this.change3DAttr = 'x'
    },
    inputValue3DY(newVale) {
      this.change3DTypeVale = newVale
      this.change3DAttr = 'y'
    },
  },
  computed:{
    bigMatchImgStyle() {
      return `
         margin-top: ${-168 + this.inputValueY}px;
         margin-left: ${-168 + this.inputValueX}px;
         transform: rotate(${this.inputValueR}deg) scale(${this.inputValueS}) rotate3d(${this.change3DAttr === 'x' ? 1 : 0}, ${this.change3DAttr === 'y' ? 1 : 0}, ${this.change3DAttr === 'z' ? 1 : 0}, ${this.change3DTypeVale}deg);
       `
    },
  },
  async mounted(){
      if (localStorage.getItem('BIG_IMG_INPUT_VALUE_INFO')) {
        const tempData = JSON.parse(localStorage.getItem('BIG_IMG_INPUT_VALUE_INFO'))
        this.inputValueS = tempData.inputValueS
        this.inputValueR = tempData.inputValueR
        this.inputValueX = tempData.inputValueX
        this.inputValueY = tempData.inputValueY
        this.inputValue3DZ =  tempData.inputValue3DZ
        this.inputValue3DY =  tempData.inputValue3DY
        this.inputValue3DX =  tempData.inputValue3DX
      }
      if (sessionStorage.getItem('checkMatch')) {
        this.isFiringCheckMatch = JSON.parse(sessionStorage.getItem('checkMatch'))
      }
      if (this.showBigCoinStatus) {

      }
      this.$nextTick(e=>{
        this.maxWidth = this.$refs['imgel'].clientWidth
        this.maxHeight = this.$refs['imgel'].clientHeight

        addEventListener('keydown',(e)=>{
          if(e.key =='Escape'){
            this.close()
          }
        });
        addEventListener('mousewheel',(e)=>{
          this.onmousewheelMove(e)
        })
        addEventListener('wheel',(e)=>{
          this.onmousewheelMove(e)
        })
      })

    },
    components:{},
    methods:{
      /** 重置 */
      handleResetInputValue() {
        this.inputValueS = 1.00
        this.inputValueR = 0
        this.inputValueX = 0
        this.inputValueY = 0
        this.inputValue3DZ =  0
        this.inputValue3DY =  0
        this.inputValue3DX =  0
      },
      /** 保存设置 */
      handleSaveInputValue() {
        const tempData = {
          inputValueS: this.inputValueS,
          inputValueR: this.inputValueR,
          inputValueX: this.inputValueX,
          inputValueY: this.inputValueY,
          inputValue3DZ: this.inputValue3DZ,
          inputValue3DY: this.inputValue3DY,
          inputValue3DX: this.inputValue3DX,
        }
        localStorage.setItem('BIG_IMG_INPUT_VALUE_INFO', JSON.stringify(tempData))
        this.$message.info('保存成功')
      },
      /** 是否启用钱币校准 */
      handleChangeFiringCheckMatch(e) {
        this.isFiringCheckMatch = e.target.checked
        sessionStorage.setItem('checkMatch', this.isFiringCheckMatch)
      },
      /** 盒子币检测错误收集 */
      async handlePushShellBadCase(index) {
        const res = await this.axios.post("/dq_admin/duibanCoinBadcase/checkShellBadcaseCollect", {
          recordId: this.showImageByListId,
          imageUrl: this.list[index].img_url,
          type: 80
        })
        if (res.status !== '200') return
        this.$message.success('收集完成')
      },
      /** 币种图片收集 */
      async handlePushBigCoinBadCase(index) {
        const res = await this.axios("/dq_admin/product/collectImageRecoBadcase", {
          params: {
            productId: this.showImageByListId,
            itemImg: this.list[index].img_url
          },
        })
        if (res.status !== '200') return
        this.$message.success('收集完成')
      },
      close(){
        removeEventListener('keydown',this.close)
        removeEventListener('mousewheel',this.onmousewheel)
        this.out = false
        setTimeout(()=>{
          document.body.removeChild(this.dom)
        },400)

      },
      async previous(){
        this.currentIndex--
        if(this.currentIndex<0){
          this.currentIndex = this.list.length-1
        }
      },
      async next(){
        this.currentIndex++
        if(this.currentIndex>this.list.length-1){
          this.currentIndex = 0
        }
      },
      async current(n){
        this.currentIndex = n
      },
      openDetail(url){
        this.axios('/dq_admin/spider_coin_goods/getInfoByOcr',{params:{url}}).then(res=>{
          const {words_result} = res.data
          let doms = document.querySelector('#tips98')
          if(doms){
            doms.remove()
          }
          this.$outsizeTips({
            list:words_result
          })
          this.close()
        })
      },
      moveCurrent(type){
        if(type == 'add'){
          this.currentIndex+=1
        }else{
          this.currentIndex-=1
        }
      },
      onmousewheelMove(e){
        const ev = e
        this.paramsData.zoomVal += ev.wheelDelta / 3000
        if (this.paramsData.zoomVal >= 0.05) {
          this.bigImgStyle = `transform:scale(${this.paramsData.zoomVal});`
        } else {
          this.paramsData.zoomVal = 0.05
          this.bigImgStyle = `transform:scale(${this.paramsData.zoomVal});`
          return false
        }
      },
      setShow(){
        this.isMore = true;
      },
      setHiden(){
        this.isMore = false;
      }
    }
}
</script>

<style lang="scss" scoped>
.jin-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.swiper-switch-box .ant-slider {
  margin-bottom: 16px;
}
.swiper-switch-box-box {
  position: absolute;
  z-index: 10000;
  width: 40%;
  bottom: 100px;
  right: 20px;
  .swiper-switch-box {
    .swiper-box-cell {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
    }
  }
}
.coin-box-button {
  top: 20% !important;
}
.coin-box {
  top: 30% !important;
  padding: 3px 12px;
  height: 26px;
  font-weight: bold;
  font-size: 24px;
  background-color: #000;
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coin-box-top-45 {
  top: 45% !important;
  padding: 6px 12px;
  height: 38px;
  font-weight: bold;
  font-size: 24px;
  background-color: blue;
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-content{width:100%;height:100%;background:rgba(0,0,0,.5);position:fixed;left:0;top:0;z-index:9999;display: flex;flex-direction: column;justify-content: space-between;
animation: inpreview .5s;
  .header{width:100%;height:50px;position: relative;color:#fff;display:flex;justify-content:center;align-items:center;
    .close{position:absolute;right:20%;cursor:pointer;font-size:30px;top:20px;z-index: 10000;
      &:hover{color:red;}
    }
  }
  .content{position: relative;width:100%;height:100%;display: flex;justify-content: center;align-items: center;padding:0 10px;
    .show-img{
      position: relative;
      width:100%;height:100%;padding:20px;display: flex;justify-content: center;align-items: center;
      .match-img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -168px;
        margin-left: -168px;
        transform-style: preserve-3d;
        width: 317px;
        height: 317px;
        z-index: 100;
      }
    }
    .next-class{position: absolute;top:50%;left:35%;transform: translateY(-50%);opacity:.5;z-index: 10000;}
    .right-class{position: absolute;top:50%;right:35%;transform: translateY(-50%);opacity:.5;z-index: 10000;}
  }
  .footer{position:absolute;bottom:0;width:100%;display:flex;justify-content:center;align-items:center;
    .preview{width:70px;height:70px;margin:0 2px;display: flex;justify-content: center;align-items: center;}
  }
  .check-box-match {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #FFFFFF !important;
  }
  .check-box-match::v-deep span {
    color: #FFFFFF !important;
  }
}
.in-footer{border:1px solid red}
.out-footer{border:1px solid transparent}
.prevent-out{animation: outpreview .5s;}
@keyframes inpreview{from {transform: scale(.1);opacity: .1;}to {transform: scale(1);opacity: 1;}}
@keyframes outpreview{from{transform: scale(1);opacity: 1;}to{transform: scale(.1);opacity: .1;}}
.right-10 {
  right: 20% !important;
}
.left-10 {
  left: 20% !important;
}
.top-20 {
  top: 20% !important;
}
.right-0 {
  right: 5% !important;
}
</style>